<!-- 印章柜详情页面 -->
<template>
    <section class="seal-tabs">
        <a-tabs @change="changTab">
            <a-tab-pane key="0" tab="柜机信息" force-render>
                <div class="card">
                    <p class="title-data">基础信息</p>
                    <div class="box-con">
                        <div class="box-left">
                            <img class="img" src="@/assets/images/yzgDetail/yzg.png" alt="">
                        </div>
                        <div class="box-right">
                            <div class="box-right-top">
                                <div class="box-data">
                                    <p class="text-data">{{ machine.resData.number || '- -' }}</p>
                                    <p class="text-title">印章柜设备编号</p>
                                </div>
                                <div class="box-data">
                                    <p class="text-data">{{ machine.resData.equipmentModel || '- -' }}</p>
                                    <p class="text-title">设备型号</p>
                                </div>
                                <div class="box-data">
                                    <p class="text-data">{{ machine.resData.name || '- -' }}</p>
                                    <p class="text-title">柜名称</p>
                                </div>
                                <div class="box-data">
                                    <p class="text-data">{{ machine.resData.firmwareEdition || '- -' }}</p>
                                    <p class="text-title">软件版本</p>
                                </div>
                                <div class="box-data">
                                    <div class="box-status">
                                        <!-- deviceState  设备状态(0离线, 1在线) -->
                                        <span class="drop"
                                            :style="{ background: machine.resData.deviceState == 0 ? '#F5212D' : '#00CA5E' }"></span>
                                        <span class="text-data">{{ machine.resData.deviceState == 0 ? '离线' : '在线' }}</span>
                                    </div>
                                    <p class="text-title">状态</p>
                                </div>

                            </div>
                            <div class="box-right-bottom">
                                <div class="box-data">
                                    <p class="text-data">{{ machine.resData.address || '- -' }}</p>
                                    <p class="text-title">设备位置</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-bottom">
                    <p class="title-data title-data-margin">柜机列表</p>
                    <a-table class="table" :pagination="false" :columns="machine.columns" :dataSource="machine.dataSource"
                        :loading="machine.loading" rowKey="index" ref="tableRef" :scroll="{ x: 400 }">
                        <!-- 柜机类型（1.主柜 0.副柜） -->
                        <template #cabinetType="{ record }">
                            <span>{{ record.cabinetType == 1 ? '主柜' : '副柜' }}</span>
                        </template>
                        <!-- 柜机名称 -->
                        <template #cabinetName="{ record }">
                            <span>{{ record.cabinetName || "- -" }}</span>
                        </template>

                    </a-table>
                </div>
            </a-tab-pane>
            <a-tab-pane key="1" tab="格口信息">
                <div class="card card-outlets">
                    <div class="data-con">
                        <p class="text-data">{{ outlets.resData.boxNum || 0 }}</p>
                        <p class="text-title">格口数量</p>
                    </div>
                    <div class="data-con">
                        <p class="text-data">{{ outlets.resData.nowNumSum || 0 }}/{{ outlets.resData.bindNumSum || 0 }}</p>
                        <p class="text-title">智能印章数量</p>
                    </div>
                    <div class="data-con">
                        <p class="text-data text-data-abnormal">{{ outlets.resData.exceptionNum || 0 }}</p>
                        <p class="text-title">格口异常</p>
                    </div>
                </div>
                <div class="card card-seach">
                    <a-form layout="inline">
                        <a-form-item label="所属柜机：" class="block block-bottom">
                            <a-select class="form-select" v-model:value="outlets.screen.cabinetId" placeholder="请选择">
                                <a-select-option :value="item.code"
                                    v-for="(item, index) in outlets.screenOptions.cabinetIdOptions" :key="index">{{
                                        item.desc
                                    }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="RFID标签" class="block">
                            <a-input class="form-input" allowClear 
                                v-model:value.trim="outlets.screen.rfidNumber" placeholder="请输入">
                            </a-input>
                        </a-form-item>
                        <a-form-item label="物品名称" class="block">
                            <a-input class="form-input" allowClear :maxlength="20"
                                v-model:value.trim="outlets.screen.goodsName" placeholder="请输入">
                            </a-input>
                        </a-form-item>
                        <a-form-item label="格口状态" class="block">
                            <a-select class="form-select" v-model:value="outlets.screen.boxStatus" placeholder="请选择">
                                <a-select-option :value="item.code"
                                    v-for="(item, index) in outlets.screenOptions.boxStatusOptions" :key="index">{{
                                        item.desc
                                    }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="格口编号" class="block">
                            <a-input class="form-input" allowClear :maxlength="20"
                                v-model:value.trim="outlets.screen.boxNumber" placeholder="请输入">
                            </a-input>
                        </a-form-item>
                        <a-form-item label="箱门状态" class="block">
                            <a-select class="form-select" v-model:value="outlets.screen.boxOpenStatus" placeholder="请选择">
                                <a-select-option :value="item.code"
                                    v-for="(item, index) in outlets.screenOptions.boxOpenStatusOptions" :key="index">{{
                                        item.desc
                                    }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="物品类型" class="block">
                            <a-select class="form-select" v-model:value="outlets.screen.boxType" placeholder="请选择">
                                <a-select-option :value="item.code"
                                    v-for="(item, index) in outlets.screenOptions.boxTypeOptions" :key="index">{{
                                        item.desc
                                    }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item class="form-btns" style="margin-right: 0;">
                            <a-button @click="reset" class="reset">重置</a-button>
                            <a-button @click="getBoxDetailList" type="primary">查询</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="card card-table">
                    <div class="box-head">
                        <span class="text-title">格口信息</span>
                        <a-button class="btn-inventory" @click="inventory(true)">盘点</a-button>
                    </div>
                    <a-table class="table" :pagination="false" :columns="outlets.columns" :dataSource="outlets.dataSource"
                        :loading="outlets.loading" rowKey="index" ref="tableRef" :scroll="{ x: 400 }">
                        <!-- 在柜数量 -->
                        <template #nowNum="{ record }">
                            <span>{{ record.nowNum }}/{{ record.bindNum }}</span>
                        </template>
                        <!-- 位置 -->
                        <template #position="{ record }">
                            <span>第{{ record.boxRow }}行，第{{ record.boxCol }}列</span>
                        </template>
                        <!-- 物品类型 -->
                        <template #boxType="{ record }">
                            <span>{{ record.boxType == 1 ? '智能印章' : '- -' }}</span>
                        </template>
                        <!-- 格口名称 -->
                        <template #boxName="{ record }">
                            <span>{{ record.boxName || '- -' }}</span>
                        </template>
                        <!-- 操作-详情 -->
                        <template #action="{ record }">
                            <span @click="openActionModel(record.id)" class="text-detail">详情</span>
                        </template>
                        <!-- 格口状态 （1.有物 2.空闲 3.异常）-->
                        <template #boxStatus="{ record }">
                            <div class="box-status">
                                <span class="drop"
                                    :style="{ background: record.boxStatus == 1 ? '#A36FFF' : record.boxStatus == 2 ? '#00CA5E' : '#FF1212' }"></span>
                                <span class="text-data">{{record.boxStatus == 1 ? '有物' : record.boxStatus == 2 ? '空闲' : '异常'}}</span>
                            </div>
                        </template>
                        <!-- 箱门状态 （1.开启 0.关闭）-->
                        <template #boxOpenStatus="{ record }">
                            <span v-if="record.boxOpenStatus == 1" class="text-data-open">开启</span>
                            <span v-else>关闭</span>
                        </template>
                    </a-table>
                </div>

            </a-tab-pane>
        </a-tabs>
        <!-- 详情弹窗 -->
        <yzg-detail-model v-if="outlets.isShowDetailModel" :cabinetBoxId="cabinetBoxId"
            :handledClose="actionModel"></yzg-detail-model>
        <!-- 盘点弹窗 -->
        <a-modal class="model-inventory" wrapClassName="full-modal" width="380px" :centered="true" :bodyStyle="{
            'width': ' 380px',
            'height': '288px',
            'border-radius': '20px'
        }
            " :visible="outlets.inventoryModel" :footer="null" :title="null" :closable="false" @ok="inventory(false)">
            <div id="animationbox" class="box-animation"></div>
            <p class="text">正在盘点中，请稍后...</p>
        </a-modal>

    </section>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { machineColumns, outletsColumns } from './columns'
import yzgDetailModel from './yzgDetailModel.vue'
import { cmsNotice } from '@/utils/utils'
import { getDeviceDetail } from '@/apis/deviceManage'
import { sealCabinetApi } from '@/apis/sealCabinet'
import { useRoute, useRouter } from 'vue-router'
import { getEnums } from '@/apis/user'
import lottie from "lottie-web";

export default defineComponent({
    components: { yzgDetailModel },
    setup() {
        // 盘点动画对象
        let animationItem = null;
        const route = useRoute()
        const router = useRouter()
        // 设备id
        const equipmentId = ref('')
        // 格口id
        const cabinetBoxId = ref();
        // 柜机信息
        let machine = reactive({
            // 接口data
            resData: {},
            // 表格数据
            dataSource: [],
            // 表格列配置
            columns: machineColumns,
            // 表格loading
            loading: false
        })
        // 格口信息
        let outlets = reactive({
            // 接口data
            resData: {},
            // 盘点弹窗
            inventoryModel: false,
            // 是否展示详情弹窗
            isShowDetailModel: false,
            // 表格数据
            dataSource: [],
            // 表格列配置
            columns: outletsColumns,
            // 表格loading
            loading: false,
            // 查询参数（传给接口）
            screen: {
                // 所属柜机
                cabinetId: undefined,
                // RFID标签
                rfidNumber: undefined,
                // 物品名称
                goodsName: undefined,
                // 格口状态
                boxStatus: undefined,
                // 格口编号
                boxNumber: undefined,
                // 箱门状态
                boxOpenStatus: undefined,
                // 物品类型
                boxType: undefined,
            },
            // 有可选项的查询项下拉枚举
            screenOptions: {
                // 所属柜机可选项
                cabinetIdOptions: [],
                // 格口状态可选项
                boxStatusOptions: [],
                // 箱门状态可选项 1.开启 0.关闭 后续不回变动，写死即可
                boxOpenStatusOptions: [
                    { code: 0, desc: '关闭' },
                    { code: 1, desc: '开启' },
                ],
                // 物品类型可选项
                boxTypeOptions: []
            }
        })

        // 初始化
        onMounted(() => {
            equipmentId.value = route.query.equipmentId
            init()
        })

        //获取设备基础信息
        const init = () => {
            getDeviceDetail({ equipmentId: equipmentId.value })
                .then(res => {
                    if (res.success) {
                        machine.resData = res.data;
                    }
                })
            getCabinetDetailList()
            getCabinetSelect()
            getEnum()
        }

        // 获取枚举
        const getEnum = async () => {
            // 格口状态的枚举
            getEnums({ kind: 'CABINET_BOX_STATUS' })
                .then(res => {
                    if (res.success) {
                        outlets.screenOptions.boxStatusOptions = res.data;
                    }
                })
            // 物品类型的枚举
            getEnums({ kind: 'CABINET_BOX_TYPE' })
                .then(res => {
                    if (res.success) {
                        outlets.screenOptions.boxTypeOptions = res.data;
                    }
                })
        }

        // 获取柜机列表数据
        const getCabinetDetailList = () => {
            sealCabinetApi.cabinetDetailList({
                equipmentId: equipmentId.value
            }).then(res => {
                machine.dataSource = res.data;
            })
        }

        // 获取所属柜机枚举
        const getCabinetSelect = () => {
            sealCabinetApi.getCabinetSelect({
                equipmentId: equipmentId.value
            }).then(res => {
                outlets.screenOptions.cabinetIdOptions = res.data;
            })
        }

        // 获取格口信息
        const getBoxDetailList = () => {
            outlets.screen.equipmentId = equipmentId.value,
                sealCabinetApi.boxDetailList(outlets.screen).then(res => {
                    if (res.code === 1083) {
                        console.log(router);
                        router.push({path:'/equipmentManage'});
                        return;
                    }
                    outlets.resData = res.data;
                    outlets.dataSource = res.data.cabinetBoxList;
                })
        }

        // 操作详情弹窗
        const actionModel = () => {
            outlets.isShowDetailModel = false;
        }

        // 打开详情弹窗
        const openActionModel = (id) => {
            cabinetBoxId.value = id;
            outlets.isShowDetailModel = true;
        }

        // Tab切换
        const changTab = (index) => {
            console.log("切换啦", index);
            if (index = 1) {
                getBoxDetailList()
            } else {
                init()
            }
        };

        // 重置
        const reset = () => {
            outlets.screen = {}
            getBoxDetailList()
        }

        // 盘点
        const inventory = (isOpen) => {
            if (isOpen) {
                outlets.inventoryModel = true;
                // 需要延时加载动画，否则页面无画面
                setTimeout(() => {
                    playAnimation()
                }, 200);
                // 每1s轮询一次盘点接口
                const timer = setInterval(() => {
                    sealCabinetApi.panDian(equipmentId.value)
                        .then(res => {
                            if (res.success && res.data.progressBar == 100) {
                                clearInterval(timer);
                                outlets.inventoryModel = false;
                                cmsNotice('success', `盘点完成，盘点异常格口数${res.data.exceptionBoxNum}`)
                                animationItem && lottie.destroy(animationItem.name);
                            } else if (!res.success) {
                                clearInterval(timer);
                                outlets.inventoryModel = false;
                                animationItem && lottie.destroy(animationItem.name);
                            }
                        })

                }, 1000);
            } else {
                outlets.inventoryModel = false;
                animationItem && lottie.destroy(animationItem.name);
            }
        }

        // 播放盘点弹窗上的动画
        const playAnimation = () => {
            console.log("akakakak");
            const animationContainer = document.getElementById('animationbox');
            const animationData = require('./../../assets/json/inventory.json');
            animationItem = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationData
            });
        }

        return {
            changTab,
            reset,
            actionModel,
            inventory,
            openActionModel,
            getBoxDetailList,
            machine,
            outlets,
            cabinetBoxId
        }
    }
})
</script>
<style lang="less" scoped>
@import url('./yzgDetail.less');
</style>

<!-- 以下为了修改弹窗原生样式，如果添加了scoped样式就会失效-->
<style lang="less" > .model-inventory {
     .ant-modal-content {
         border-radius: 20px;
     }

     .ant-modal-body {
         padding: 40px 32px;
         box-sizing: border-box;
     }
 }
</style>